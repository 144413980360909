/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getRewardsBalance } from '@/utils/getRewardsBalance';
import { useSelector } from 'react-redux';
import { type ReduxState } from '@/types';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import type { PageViewCall, PageSpecificAnalyticsDataType } from '@/types/analytics';
import { useRouter } from 'next/router';
import { useRewardDetails } from '@/features/rewards/api/getRewardDetails';
import { getKiboDecisionFromCache } from '@/features/kibo/api/getKiboDecision';
import { useQueryClient } from '@tanstack/react-query';
import { sessionStorage } from '@/utils/sessionStorage';
import { persistXFusionQueryId } from '@/utils/xFusionQueryIdHelpers';
import { getDecodedCookies, setCookie } from '../cookie';
import { trackAddVehicle } from '../trackAddVehicle';
import { LinkRewardsContext } from '@/components/LinkRewardsContext';
import { useContext } from 'react';
import { STAND_ALONE_USER_TYPE } from '@/features/header';
import { useMyProfile } from '@/features/myProfile/api/getMyProfile';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';

export const usePageViewCall = (): PageViewCall => {
  const { showRewardSigninView } = useContext(LinkRewardsContext);
  const locale = useLocale();
  const isUS = locale === countryCodes.us;
  const { data: headerData } = useHeaderData();
  const { data: storeDetails } = useStoreDetailsData();
  const { data: userData } = useMyProfile();
  const userType = userData?.userType;
  const isStandAloneUser = userType === Number(STAND_ALONE_USER_TYPE);
  const appData = useSelector(({ appData }: ReduxState) => appData);
  const userAuthenticated = useSelector(({ appData }: ReduxState) => appData.userAuthenticated);
  const { data: rewardCardNumber } = useRewardDetails();
  const isClient = typeof document !== 'undefined';
  const router = useRouter();
  const searchRedirectText =
    typeof router.query.searchText === 'string' ? router.query.searchText : '';
  const queryClient = useQueryClient();
  const cookies = isClient ? getDecodedCookies(['autoLogin']) : null;
  const isPersistentLogin = cookies?.get('autoLogin') ?? (userAuthenticated ? 'false' : '');
  const vehiclesInfo = headerData?.vehicleMap;
  const vehicleId = vehiclesInfo?.catalogVehicleId;
  const prevSessionVehicleId = sessionStorage.getItem('previousPreferredVehicleId');
  const isAddByURL = router.query.vehicleId === vehicleId && vehicleId !== prevSessionVehicleId;

  return (
    pageSpecificAnalyticsData: PageSpecificAnalyticsDataType,
    pageSpecificRemoveAnalyticsData?: string[] | null
  ) => {
    const lwTestExperience = pageSpecificAnalyticsData?.lwTestExperience;
    const productRank = pageSpecificAnalyticsData?.productRank;
    const xFusionQueryId = pageSpecificAnalyticsData?.xFusionQueryId;
    const allSearchTerms = window.utag_data?.allSearchTerms;
    const loginFlow = window.utag_data?.loginFlow;
    const loginInteractionMethod = window.utag_data?.loginInteractionMethod;
    const loginMethod = window.utag_data?.loginMethod;
    const authenticationStatus = appData.userAuthenticated;
    const rewardsCustomerStatus = headerData?.myAccountMap?.rewardsId?.length ? true : false;
    const profileId = headerData?.myAccountMap?.profileId ?? '';
    const rewardsId = headerData?.myAccountMap?.rewardsId ?? '';
    const jSessionId = window.utag_data?.jSessionId;
    const eCookieId = window.utag_data?.eCookieId;
    const preferredZipCode = storeDetails?.zip ?? '';
    const preferredStoreId = storeDetails?.storeNumber ?? '';
    const persistentLogin = isPersistentLogin;
    const { vehicleMap } = headerData ?? {};
    const preferredVehicleYear = vehicleMap?.year ?? '';
    const preferredVehicleMake = vehicleMap?.make ?? '';
    const preferredVehicleModel = vehicleMap?.model ?? '';
    const preferredVehicleEngine =
      vehicleMap?.vehicleName
        .replace(`${preferredVehicleYear} ${preferredVehicleMake} ${preferredVehicleModel}`, '')
        ?.trim() ?? '';
    const preferredVehicleId = vehicleMap?.catalogVehicleId ?? '';
    const userVehicleCount = window.utag_data?.userVehicleCount ?? '0';
    const serverInfo = window.utag_data?.serverInfo;
    const device = appData.deviceType;
    const userType = headerData?.myAccountMap?.userType ?? ''; // we can get some of the "previous" information from the utag data
    const previousPageName = window.utag_data?.pageName ?? '';
    const previousPageType = window.utag_data?.pageType ?? '';
    //To determine whether the certona script call loaded correctly or  not.
    const certonaCalled = !window?.certonaNotCalled;
    const previousPageSiteSection = window.utag_data?.siteSection ?? '';
    const pageURL = window.utag_data?.pageURL ?? '';
    const previousPageURL = window.utag_data?.previousPageURL ?? '';
    const referrer = window.utag_data?.referrer ?? '';
    const nddMarket = headerData?.analyticsData?.nddMarket ?? '';
    const pageLazyLoad = pageSpecificAnalyticsData.pageLazyLoad ?? false;
    const searchMethod = pageSpecificAnalyticsData.searchMethod ?? '';
    const nddHub = headerData?.analyticsData?.nddHubStore ?? '';
    const nddStore = headerData?.analyticsData?.nddStore ?? '';
    const currentNumberOfCredits = rewardCardNumber?.currentNumberOfCredits ?? '';
    const rewardsBalance = rewardCardNumber?.rewardBalance ?? '';
    const availableRewardBalance = rewardsBalance ? getRewardsBalance(rewardsBalance) : '';
    const hashedEId = window.utag_data?.hashedEId ?? '';
    const revenueBucket = window.utag_data?.revenueBucket ?? '';
    const typeaheadValue = sessionStorage.getItem('typeAheadValue');
    const visualPartTypeDisplayed = pageSpecificAnalyticsData?.visualPartTypeDisplayed;
    const partTypeFiltersAvailable = pageSpecificAnalyticsData?.partTypeFiltersAvailable;

    const kiboData = getKiboDecisionFromCache(queryClient);
    const experienceNames = kiboData?.actions
      ? Object.values(kiboData.actions).map(
          (action) =>
            `${action.impressionReporting[0].experience_label} | ${action.impressionReporting[0].variant_label}`
        )
      : undefined;

    const data = {
      allSearchTerms,
      authenticationStatus,
      availableRewardBalance,
      currentNumberOfCredits,
      eCookieId,
      rewardsCustomerStatus,
      profileId,
      rewardsId,
      jSessionId,
      preferredZipCode,
      preferredStoreId,
      preferredVehicleYear,
      preferredVehicleMake,
      preferredVehicleModel,
      preferredVehicleEngine,
      preferredVehicleId,
      userVehicleCount,
      serverInfo,
      device,
      userType,
      previousPageName,
      previousPageType,
      previousPageSiteSection,
      pageURL,
      previousPageURL,
      referrer,
      nddMarket,
      nddHub,
      nddStore,
      hashedEId,
      revenueBucket,
      pageLazyLoad,
      loginFlow,
      loginInteractionMethod,
      loginMethod,
      searchMethod,
      experienceNames,
      lwTestExperience,
      certonaCalled,
      visualPartTypeDisplayed,
      persistentLogin,
      partTypeFiltersAvailable,
      ...(!!productRank && {
        productRank,
      }),
      ...(!!xFusionQueryId && {
        xFusionQueryId,
      }),
      ...(!!searchRedirectText && {
        searchKeyword: searchRedirectText,
        eventType: 'successfulSearch',
      }),
      ...(!!typeaheadValue && {
        searchKeyword: typeaheadValue,
        eventType: 'successfulSearch',
      }),
      ...pageSpecificAnalyticsData,
      productFindingMethod: pageSpecificAnalyticsData.productFindingMethod ?? '',
    };
    if (
      !data.lwTestExperience ||
      (data.pageType.includes('shelf') && !router.asPath.includes('search'))
    ) {
      delete data.lwTestExperience;
    }

    if (
      !data.eventType ||
      (!router.asPath.includes('searchresult') &&
        !router.asPath.includes('redirect') &&
        data.eventType !== 'loadMore' &&
        !data.selectedFilterValue)
    ) {
      delete data.eventType;
      delete window.utag_data?.['eventType'];
    }

    if (!data.visualPartTypeDisplayed || !router.asPath.includes('searchresult')) {
      delete data.visualPartTypeDisplayed;
    }

    if (
      !data.partTypeFiltersAvailable ||
      (!data.pageType.includes('shelf') &&
        !data.pageType.includes('Brand Profile') &&
        !router.asPath.includes('searchresult'))
    ) {
      delete data.partTypeFiltersAvailable;
    }

    // persist xFusionQueryId so it can be passed via /item request
    persistXFusionQueryId(xFusionQueryId);

    pageSpecificRemoveAnalyticsData?.length &&
      pageSpecificRemoveAnalyticsData.forEach((removeItem) => {
        // @ts-expect-error refine type
        delete data[removeItem];
        // @ts-expect-error refine type
        delete window.utag_data[removeItem];
      });

    window.utag_data = data;
    window.utag?.view(data);
    sessionStorage.removeItem('typeAheadValue');
    if (isPersistentLogin == 'true') {
      if (isUS && isStandAloneUser) {
        showRewardSigninView(true);
      }
      setCookie('autoLogin', 'false');
    }
    if (isAddByURL) {
      trackAddVehicle(isAddByURL, headerData);
    }
  };
};
